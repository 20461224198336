<template>
  <v-card id="top-page" style="background-color: #eef2f5">
    <v-card class="container_main d-flex align-start justify-space-between rounded-0 bg" width="100vw"
      min-height="100vh" height="wrap-content">
      <div class="cards">
        <v-card class="banner">
          <h4 style="margin-left: 20px; font-size: 1rem">
            {{
              isSupplier
                ? "Subcontract Portal"
                : "Maintenance Scheduling Portal"
            }}
          </h4>
          <h4 style="font-size: 1rem">
            {{
                isSupplier
                  ? `Hi ${getUserInfo.name}, SupplierID: ${getUserInfo.vendor_id}`
                  : `Company ${userInfo.company}, Site MFG`
              }}
          </h4>
        </v-card>
        <div class="cards" style="width: 100%" v-show="isSupplier">
          <v-card @click="$router.push('/my-subcontract-job')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>My Subcontract Jobs</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-file-document-edit" }}</v-icon>
              <h1>
                {{ numberOfItem.Calculated_NumberOfOperSubcontract || 0 }}
              </h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/job-calendar')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Job Calendar</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-calendar-clock" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfReqJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/meter-reading')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Meter Reading</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-forklift" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfMetterReading || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/managed-equipment')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Managed Equipments</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-forklift" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfEquip || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/case')" class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Cases</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-ticket" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfCase || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/maintenance-request')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Maintenance Requests</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-tray-full" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfReqJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/managed-inventory')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Managed Inventory</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-warehouse" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfInv || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/timesheet')" class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Timesheet</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-bulletin-board" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfMTNJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/purchase-order')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Purchase Order</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-receipt-text-outline" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfPO || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/delivery')" class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Delivery</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-truck-delivery" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfDelivery || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/billing')" class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Billing</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-receipt-clock-outline" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfBilling || 0 }}</h1>
            </v-card>
          </v-card>
          <!-- <v-card
            @click="$router.push('/AgedCare-Form')"
            class="card_item d-flex flex-column justify-center align-center"
          >
            <div class="d-flex"></div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-hospital-box" }}</v-icon>
              <h2>Aged Care Form</h2>
            </v-card>
          </v-card> -->
        </div>
        <div class="cards" style="width: 100%" v-show="!isSupplier">
          <v-card @click="$router.push('/scheduling-board')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Jobs Scheduling Board</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-bulletin-board" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfMTNJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/operation-timeline')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Operations Timeline</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-timeline-alert" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfMTNJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/maintenance-job')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Maintenance Job</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-wrench-clock" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfMTNJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/preventative-job')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Preventative Job</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-shield-alert" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfPrevJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/maintenance-request-queue')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Maintenance Request Queue</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-tray-full" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfReqJob || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/equip-location')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Facility - Location</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-folder-marker" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfEquipLocation || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/meter-reading')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Meter Reading</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-forklift" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfMetterReading || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/equip-management')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Equipment Management</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-forklift" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfEquip || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/case-management')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex">
              <p>Case Management</p>
            </div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-ticket" }}</v-icon>
              <h1>{{ numberOfItem.Calculated_NumberOfCase || 0 }}</h1>
            </v-card>
          </v-card>
          <v-card @click="$router.push('/AgedCare-Form')"
            class="card_item d-flex flex-column justify-center align-center">
            <div class="d-flex"></div>
            <v-card class="d-flex card-inside">
              <v-icon>{{ "mdi-hospital-box" }}</v-icon>
              <h2>Aged Care Form</h2>
            </v-card>
          </v-card>
        </div>
      </div>
      <div class="card_request_div_container" v-show="!userInfo.isUseCase">
        <div class="card_request_div">
          <v-expansion-panels class="card_request" multiple v-model="panel">
            <v-expansion-panel style="background-color: #eef2f5">
              <v-expansion-panel-header class="Header">Submit Request (Request Entry)</v-expansion-panel-header>
              <v-expansion-panel-content class="v-expansion-panel-content" style="height: wrap-content">
                <v-card class="pa-10 d-flex flex-column align-center justify-space-between rounded-md card" width="100%"
                  height="wrap-content">
                  <div style="width: 100%" class="d-flex flex-column align-center justify-space-between">
                    <v-text-field :name="'input' + Date().toString()" :label="'Equipment ' + '*'" color="#333333" light
                      v-model="requestBody.equipmentID" class="input-group--focused" style="width: 100%"
                      @click="showEquipmentDialog(true)" />
                    <div class="request_date">
                      <h6 class="title">Required Date:</h6>
                      <input class="date" type="date" v-model="requestBody.requireDate" />
                    </div>
                    <v-text-field :name="'input' + Date().toString()" style="margin-top: 20px;width: 100%"
                      :label="'Priority ' + '*'" color="#333333" light v-model="requestBody.schedCode" :readonly="true"
                      class="input-group--focused" @click="searchPriority" />

                    <v-text-field :name="'input' + Date().toString()" style="width: 100%"
                      :label="'Issue Description ' + '*'" color="#333333" light v-model="requestBody.hdResDescription"
                      class="input-group--focused" />

                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" :label="'Topic 1 ' + '*'"
                      color="#333333" light v-model="requestBody.issueTopicID1" class="input-group--focused"
                      :readonly="true" @click="searchTopic1" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Topic 2"
                      color="#333333" light v-model="requestBody.issueTopicID2" class="input-group--focused"
                      :readonly="true" @click="searchTopic2(requestBody.issueTopicID1)" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Topic 3"
                      color="#333333" light v-model="requestBody.issueTopicID3" class="input-group--focused"
                      :readonly="true" @click="searchTopic3(requestBody.issueTopicID2)" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Topic 4"
                      color="#333333" light v-model="requestBody.issueTopicID4" class="input-group--focused"
                      :readonly="true" @click="searchTopic4(requestBody.issueTopicID3)" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Topic 5"
                      color="#333333" light v-model="requestBody.issueTopicID5" class="input-group--focused"
                      :readonly="true" @click="searchTopic5(requestBody.issueTopicID4)" />

                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" :label="'Resolution 1 ' + '*'"
                      color="#333333" light v-model="requestBody.resTopicID1" class="input-group--focused"
                      :readonly="true" @click="searchResolution1" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Resolution 2"
                      color="#333333" light v-model="requestBody.resTopicID2" class="input-group--focused"
                      :readonly="true" @click="searchResolution2(requestBody.resTopicID1)" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Resolution 3"
                      color="#333333" light v-model="requestBody.resTopicID3" class="input-group--focused"
                      :readonly="true" @click="searchResolution3(requestBody.resTopicID2)" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Resolution 4"
                      color="#333333" light v-model="requestBody.resTopicID4" class="input-group--focused"
                      :readonly="true" @click="searchResolution4(requestBody.resTopicID3)" />
                    <v-text-field :name="'input' + Date().toString()" style="width: 100%" label="Resolution 5"
                      color="#333333" light v-model="requestBody.resTopicID5" class="input-group--focused"
                      :readonly="true" @click="searchResolution5(requestBody.resTopicID4)" />
                    <input style="width: 100%" type="file" @change="onFileChanged($event)" accept="*" capture />
                    <div style="width: 100%" class="d-flex align-center justify-center">
                      <g-recaptcha style="width: 100%" data-sitekey="6LcEqtQlAAAAAGHpVRo-Yb6sVo0o_3fa9jT2qhoP"
                        class="input-group--focused d-flex justify-center" :data-validate="validate"
                        :data-callback="callback">
                        <v-btn elevation="2" min-width="150px" :loading="loader" :disabled="loading"
                          @click="loader = 'loading'" style="color: #1a535e; color: #1a535e"
                          class="button-submit input-group--focused">Submit Request</v-btn>
                      </g-recaptcha>
                    </div>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div class="card_request_div_container" v-show="userInfo.isUseCase">
        <div class="card_request_div">
          <v-expansion-panels class="card_request" multiple v-model="panel">
            <v-expansion-panel style="background-color: #eef2f5">
              <v-expansion-panel-header class="Header">Submit Request</v-expansion-panel-header>
              <v-expansion-panel-content class="v-expansion-panel-content" style="height: wrap-content">
                <v-card class="pa-10 d-flex flex-column align-center justify-space-between rounded-md card" width="100%"
                  height="wrap-content">
                  <div style="width: 100%" class="d-flex flex-column align-center justify-space-between">
                    <v-text-field name="input-10-2" :label="'Name ' + '*'" color="#333333" value="" light
                      v-model="userInfo.name" class="input-group--focused" style="width: 100%" />

                    <v-text-field name="input-10-2" :label="'Email ' + '*'" v-model="userInfo.email" color="#333333"
                      value="" class="input-group--focused" style="width: 100%" />

                    <v-text-field name="input-10-2" :label="'Phone ' + '*'" v-model="userInfo.phone" color="#333333"
                      value="" class="input-group--focused" style="width: 100%" />

                    <v-text-field name="input-10-2" :label="'Company name ' + '*'" v-model="userInfo.company" value=""
                      color="#333333" class="input-group--focused" style="width: 100%" />

                    <v-text-field name="input-10-2" :label="'Equipment ID ' + '*'" v-model="userInfo.equipment"
                      color="#333333" value="" class="input-group--focused" style="width: 100%"
                      @click="showEquipmentDialog" />

                    <v-text-field name="input-10-2" :label="'Equipment Serial Number ' + '*'"
                      v-model="userInfo.equipment_serial" value="" color="#333333" class="input-group--focused"
                      style="width: 100%" @click="showEquipmentSerialDialog = true" />

                    <v-text-field name="input-10-2" :label="'Comments ' + '*'" v-model="userInfo.comment"
                      color="#333333" value="" class="input-group--focused" style="width: 100%" />
                    <div v-if="isError" class="d-flex justify-start" style="width: 100%; color: red">
                      <h5>*Please fill in all information</h5>
                    </div>
                    <div style="width: 100%" class="d-flex align-center justify-center">
                      <g-recaptcha style="width: 100%" data-sitekey="6LcEqtQlAAAAAGHpVRo-Yb6sVo0o_3fa9jT2qhoP"
                        class="input-group--focused d-flex justify-center" :data-validate="validate"
                        :data-callback="callback">
                        <v-btn elevation="2" min-width="150px" :loading="loader" :disabled="loading"
                          @click="loader = 'loading'" style="color: #1a535e; color: #1a535e"
                          class="button-submit input-group--focused">Submit Request</v-btn>
                      </g-recaptcha>
                    </div>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="showEquipmentSerialDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">#{{ this.userInfo.equipment }} Equipment Serial Number</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="equipmentSearch" label="Search" color="#333333" style="width: 25%"></v-text-field>
          <tableComponent :tableHeaders="equipmentSerialHeader" :tableItems="getEquipmentSerialNumber"
            :search="equipmentSearch" parent="equipment-serial" @selectedEquipmentSerial="handleEquipSerialRowClick">
          </tableComponent>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showEquipmentSerialDialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEquipmentIdDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Equipment ID</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="equipmentSearch" label="Search" color="#333333" style="width: 25%"></v-text-field>
          <tableComponent :tableHeaders="equipmentHeader" :tableItems="equipmentList" :search="equipmentSearch"
            parent="equipment-id" @selectedEquipmentId="handleEquipIdRowClick">
          </tableComponent>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showEquipmentIdDialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ keySearch }} List</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="dialogSearch" label="Search" color="#333333" style="width: 25%"></v-text-field>
          <tableComponent :tableHeaders="dialogHeader" :tableItems="dialogItem" :search="dialogSearch" parent="N"
            @selected="selected">
          </tableComponent>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import gRecaptcha from "@finpo/vue2-recaptcha-invisible";
import tableComponent from "../../table/tableComponent.vue";
import { mapGetters } from "vuex";
import DecodeJWT from "../DecodeJWT";
import { callBAQ, methodCreateRequest, callMethodFunc } from "../../../store/Method/index";

export default {
  components: {
    gRecaptcha,
    tableComponent,
  },
  async mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
        localStorage.setItem("MTNToken", "");
        localStorage.setItem("userinfo", "");
        localStorage.setItem("cust_num", "");
        localStorage.setItem("cust_uid", "");
      } else {
        this.panel = Array.from(Array(1).keys());
        window.scrollTo({
          top: document.getElementById("top-page"),
          left: 0,
          behavior: "smooth",
        });
        await this.$store.dispatch("User/DSPGetCompanyInfo");
        await this.$store.dispatch("User/getInfo");
        await this.$store.dispatch("User/loadUserInfo");
        this.isSupplier =
          localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
        this.getHomeDataFunction();
        this.setData();
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, "0");
        let day = today.getDate().toString().padStart(2, "0");
        this.requestBody.requireDate = `${year}-${month}-${day}`;
      }
    }
  },
  data() {
    return {
      numberOfItem: {},
      equipmentList: [],
      dialogHeader: [],
      dialogItem: [],
      dialogSearch: "",
      showLoading: false,
      loading: false,
      loader: null,
      loadata: false,
      panel: [],
      token: "",
      isSupplier: false,
      isError: false,
      showEquipmentSerialDialog: false,
      showEquipmentIdDialog: false,
      showDialog: false,
      equipmentSearch: "",
      equipmentHeader: [
        { text: "Company", value: "Equip_Company" },
        { text: "Equipment ID", value: "Equip_EquipID" },
        { text: "Equipment Description", value: "Equip_Description" },
        { text: "Inactive", value: "Equip_InActive" },
        { text: "Brand", value: "Equip_Brand" },
        { text: "Model", value: "Equip_Model" },
        { text: "In Service Date", value: "Equip_InServiceDate" },
        { text: "Serial Number", value: "Equip_SerialNum" },
        { text: "Equipment Status", value: "Equip_StatusID" },
        { text: "Warranty Expire Date", value: "Equip_WarrantyExpDate" },
      ],
      equipmentSerialHeader: [
        { text: "Company", value: "Equip_Company" },
        { text: "Equipment ID", value: "Equip_EquipID" },
        { text: "Serial Number", value: "Equip_SerialNum" },
      ],
      requestBody: {
        requestID: "",
        reqStatus: "Pnd",
        equipmentID: "",
        requireDate: new Date(),
        schedCode: "Normal",
        isOpenReq: true,
        issueDescription: "",
        issueTopicID1: '',
        issueTopicID2: '',
        issueTopicID3: '',
        issueTopicID4: '',
        issueTopicID5: '',
        resTopicID1: '',
        resTopicID2: '',
        resTopicID3: '',
        resTopicID4: '',
        resTopicID5: '',
        resDescription: "",
        hdResDescription: ""
      },
      isEquipForRequest: false,
      keySearch: "",
      file: ""
    };
  },
  computed: {
    ...mapGetters("User", ["getUserInfo", "getPermission"]),
    userInfo() {
      return {
        name: this.getUserInfo.name,
        email: this.getUserInfo.email,
        phone: this.getUserInfo.phone,
        company: this.getUserInfo.company.toUpperCase(),
        company_address: this.getUserInfo.company_address,
        equipment_serial: "",
        equipment: "",
        token: "",
        comment: "",
        isUseCase: this.getUserInfo.isUseCase,
      };
    },
    vendor_num() {
      return this.getUserInfo.vendor_num;
    },
    getEquipmentSerialNumber() {
      return this.equipmentList
        .filter((item) => item.Equip_EquipID === this.userInfo.equipment)
        .map((item) => ({
          Equip_Company: item.Equip_Company,
          Equip_EquipID: item.Equip_EquipID,
          Equip_SerialNum: item.Equip_SerialNum,
        }));
    },
  },
  watch: {
    vendor_num() {
      this.getHomeDataFunction();
    },
  },
  methods: {
    onFileChanged(e) {
      var that = this;
      const file = e.target.files[0];
      console.log(file)
      const reader = new FileReader();

      reader.onload = function (e) {
        that.file = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(file);
    },
    async getHomeDataFunction() {
      let isSupplier =
        localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
      await callBAQ("DSP-GetHomeMTNPortal", {
        VendorNum: !isSupplier ? "0" : this.vendor_num + "",
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          if (res.length > 0) Object.assign(this.numberOfItem, res[0]);
          this.$forceUpdate();
        }
      });
    },
    async showEquipmentDialog(isRequest) {
      if (isRequest) this.isEquipForRequest = isRequest;
      this.showLoading = true;
      await callBAQ("DSPGetEquipments", {
        CustNum: localStorage.getItem("cust_num") + "",
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showEquipmentIdDialog = true;
          this.showLoading = false;
          Object.assign(this.equipmentList, res);
          this.$forceUpdate();
        }
      });
    },
    async searchPriority() {
      this.showLoading = true;
      this.keySearch = "Priority";
      await callBAQ("DVMPriority", {}).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchTopic1() {
      this.showLoading = true;
      this.keySearch = "Topic 1";
      await callBAQ("DVMParentTopList", {}).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchTopic2(val) {
      this.showLoading = true;
      this.keySearch = "Topic 2";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchTopic3(val) {
      this.showLoading = true;
      this.keySearch = "Topic 3";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchTopic4(val) {
      this.showLoading = true;
      this.keySearch = "Topic 4";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchTopic5(val) {
      this.showLoading = true;
      this.keySearch = "Topic 5";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchResolution1() {
      this.showLoading = true;
      this.keySearch = "Resolution 1";
      await callBAQ("DVMParentTopList", {
        MaintRes: "true"
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchResolution2(val) {
      this.showLoading = true;
      this.keySearch = "Resolution 2";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchResolution3(val) {
      this.showLoading = true;
      this.keySearch = "Resolution 3";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchResolution4(val) {
      this.showLoading = true;
      this.keySearch = "Resolution 4";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    async searchResolution5(val) {
      this.showLoading = true;
      this.keySearch = "Resolution 5";
      await callBAQ("DVMTopicList", {
        ParentTopicID: val
      }).then((res) => {
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          this.showDialog = true;
          this.showLoading = false;
          let returnData = this.loadData(res);
          (this.dialogHeader = returnData.h), (this.dialogItem = returnData.i);
          this.$forceUpdate();
        }
      });
    },
    selected(item) {
      this.showDialog = false;
      switch (this.keySearch) {
        case "Priority":
          this.requestBody.schedCode = item.Calculated_Priority;
          break;
        case "Topic 1":
          this.requestBody.issueTopicID1 = item.HDTopic_TopicID;
          break;
        case "Topic 2":
          this.requestBody.issueTopicID2 = item.HDTopicParent_TopicID;
          break;
        case "Topic 3":
          this.requestBody.issueTopicID3 = item.HDTopicParent_TopicID;
          break;
        case "Topic 4":
          this.requestBody.issueTopicID4 = item.HDTopicParent_TopicID;
          break;
        case "Topic 5":
          this.requestBody.issueTopicID5 = item.HDTopicParent_TopicID;
          break;
        case "Resolution 1":
          this.requestBody.resTopicID1 = item.HDTopic_TopicID;
          break;
        case "Resolution 2":
          this.requestBody.resTopicID2 = item.HDTopicParent_TopicID;
          break;
        case "Resolution 3":
          this.requestBody.resTopicID3 = item.HDTopicParent_TopicID;
          break;
        case "Resolution 4":
          this.requestBody.resTopicID4 = item.HDTopicParent_TopicID;
          break;
        case "Resolution 5":
          this.requestBody.resTopicID5 = item.HDTopicParent_TopicID;
          break;
      }
    },
    setData() {
      this.userInfo.name = this.getUserInfo.name;
      this.userInfo.email = this.getUserInfo.email;
      this.userInfo.phone = this.getUserInfo.phone;
      this.userInfo.company = this.getUserInfo.company.toUpperCase();
      this.userInfo.company_address = this.getUserInfo.company_address;
    },
    handleEquipIdRowClick(item) {
      if (this.isEquipForRequest) this.requestBody.equipmentID = item.Equip_EquipID;
      else {
        this.userInfo.equipment = item.Equip_EquipID;
        this.userInfo.equipment_serial = "";
      }
      this.equipmentSearch = "";
      this.showEquipmentIdDialog = false;
    },
    handleEquipSerialRowClick(item) {
      this.userInfo.equipment_serial = item.Equip_SerialNum;
      this.equipmentSearch = "";
      this.showEquipmentSerialDialog = false;
    },
    validate() {
      return true;
    },
    async callback(token) {
      if (token) {
        if (this.userInfo.isUseCase) {
          await methodCreateRequest(this.userInfo).then((res) => {
            if (res && res.message == "error") {
              alert(res.error);
            } else {
              this.loader = null;
              alert(
                "Thanks for your inquiry, our customer support will get in touch with you as soon as possible. Your ticket number is " +
                res +
                "."
              );
            }
          });
        } else {
          let bo = 'Erp.BO.MaintReq';
          let body = {
            ds: {},
            plant: "MfgSys",
          };
          await callMethodFunc(bo, 'GetNewMaintReq', body).then((res) => {
            if (res && res.message == "error") {
              alert("res" + res.error);
              this.loader = null;
            } else {
              var returnObj = JSON.parse(JSON.parse(res.data).data).parameters.ds
                .MaintReq[0];
              console.log(returnObj.ReqID)
              returnObj.OpenReq = true;
              returnObj.ReqStatus = this.requestBody.reqStatus;
              returnObj.Priority = this.requestBody.schedCode;
              returnObj.RowMod = "A";
              returnObj.EquipID = this.requestBody.equipmentID;
              returnObj.RequiredDate = this.requestBody.requireDate;
              returnObj.IssueTopicID1 = this.requestBody.issueTopicID1;
              returnObj.IssueTopicID2 = this.requestBody.issueTopicID2;
              returnObj.IssueTopicID3 = this.requestBody.issueTopicID3;
              returnObj.IssueTopicID4 = this.requestBody.issueTopicID4;
              returnObj.IssueTopicID5 = this.requestBody.issueTopicID5;
              returnObj.ResTopicID1 = this.requestBody.resTopicID1;
              returnObj.ResTopicID2 = this.requestBody.resTopicID2;
              returnObj.ResTopicID3 = this.requestBody.resTopicID3;
              returnObj.ResTopicID4 = this.requestBody.resTopicID4;
              returnObj.ResTopicID5 = this.requestBody.resTopicID5;

              let body1 = {
                ds: {
                  MaintReq: [{ ...returnObj }]
                },
                equipID: this.requestBody.equipmentID
              }
              callMethodFunc(bo, "OnChangeEquipID", body1).then((res1) => {
                if (res1 && res1.message == "error") {
                  alert("res" + res1.error);
                  this.loader = null;
                } else {
                  var returnObj1 = JSON.parse(JSON.parse(res1.data).data).parameters.ds
                    .MaintReq[0];
                  returnObj1.OpenReq = true;
                  returnObj1.ReqStatus = this.requestBody.reqStatus;
                  returnObj1.Priority = this.requestBody.schedCode;
                  returnObj1.RowMod = "A";
                  returnObj1.EquipID = this.requestBody.equipmentID;
                  returnObj1.RequiredDate = this.requestBody.requireDate;
                  returnObj1.IssueDesc = this.requestBody.hdResDescription;
                  returnObj1.IssueTopicID1 = this.requestBody.issueTopicID1;
                  returnObj1.IssueTopicID2 = this.requestBody.issueTopicID2;
                  returnObj1.IssueTopicID3 = this.requestBody.issueTopicID3;
                  returnObj1.IssueTopicID4 = this.requestBody.issueTopicID4;
                  returnObj1.IssueTopicID5 = this.requestBody.issueTopicID5;
                  returnObj1.ResTopicID1 = this.requestBody.resTopicID1;
                  returnObj1.ResTopicID2 = this.requestBody.resTopicID2;
                  returnObj1.ResTopicID3 = this.requestBody.resTopicID3;
                  returnObj1.ResTopicID4 = this.requestBody.resTopicID4;
                  returnObj1.ResTopicID5 = this.requestBody.resTopicID5;
                  let body2 = {
                    ds: {
                      MaintReq: [{ ...returnObj1 }]
                    },
                  }
                  callMethodFunc(bo, "Update", body2).then((res2) => {
                    this.loader = null;
                    if (res2 && res2.message == "error") {
                      alert("res" + res2.error);
                    } else {
                      if (!this.file) alert("Your request has ID: " + returnObj1.ReqID);
                      else {
                        var returnObj2 = JSON.parse(JSON.parse(res2.data).data).parameters.ds;
                        let boAtt = "Ice.BO.Attachment";
                        let methodAtt = "UploadFile";
                        let bodyAtt = {
                          docTypeID: "ATTACH",
                          parentTable: "MaintReq",
                          fileName: "AttachReq",
                          data: this.file
                        };
                        callMethodFunc(boAtt, methodAtt, bodyAtt).then((res3) => {
                          var filename = JSON.parse(JSON.parse(res3.data).data).returnObj;

                          let body3 = {
                            ds: {
                              MaintReq: returnObj2.MaintReq,
                              MaintReqAttch: [{
                                ReqID: returnObj2.MaintReq[0].ReqID,
                                RowMod: "A"
                              }]
                            },
                            plant: "MfgSys",
                            reqID: returnObj2.MaintReq[0].ReqID,
                          }
                          callMethodFunc(bo, "GetNewMaintReqAttch", body3).then((res4) => {
                            var returnObj3 = JSON.parse(JSON.parse(res4.data).data).parameters.ds;

                            let body4 = {
                              ds: {
                                MaintReq: returnObj3.MaintReq,
                                MaintReqAttch: [{
                                  ...returnObj3.MaintReqAttch[0],
                                  FileName: filename,
                                  Company: "EPIC06",
                                  Plant: "MfgSys",
                                  DocTypeID: "",
                                  RowMod: "A"
                                }]
                              },
                              plant: "MfgSys",
                              reqID: returnObj2.MaintReq[0].ReqID,
                            }
                            callMethodFunc(bo, "Update", body4).then((res5) => {
                              if (res5 && res5.message == "error") {
                                alert("res" + res5.error);
                              }
                            })
                          })
                        })
                      }
                    }
                  })
                }
              })
            }
          });
        }
      } else {
        alert("Please check you are not robot!!!");
      }
    },
    loadData(data) {
      let item = [],
        header = [];
      for (var title in data[0]) {
        header.push({
          text: title.split("_")[1],
          value: title,
          sortable: false,
        });
      }
      if (data && data.length > 0) {
        data.forEach((el) => {
          let i = {};
          for (var e in el) {
            if (e.includes("Date")) i[e] = this.convertDate(el[e]);
            else i[e] = el[e];
          }
          item.push(i);
        });
      }
      return { h: header, i: item };
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/css/standard.scss";

.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 48px;
}

::v-deep .v-expansion-panel-header__icon {
  margin-left: 0 !important;
}

::v-deep .v-expansion-panel-header__icon {
  margin-right: 10px !important;

  .mdi-chevron-down::before {
    color: #21698f;
  }
}

.card_request_div_container {
  width: 40%;
  display: flex;
  justify-content: center;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.justify-space-between {
  justify-content: center;
}

.card_request {
  background-color: #eef2f5;
  align-items: start;
  width: 100%;
  margin: 0 10px 0 0;
}

.banner {
  width: 100%;
  height: 48px;
  background-color: #d1dfe3;
  // color: #e9f5fd;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  font-size: 1.2em;
  margin: 0 10px 0;
}

.cards {
  width: 60%;
  margin-top: 10px;
  min-height: auto;
  height: wrap-content;
  border: none;
  border-radius: 0%;
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  box-shadow: white;
  background-color: #eef2f5;
}

#top-page>.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.card_item {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-content: flex-end !important;
  height: 100px;

  div:nth-child(1) {
    width: 90%;

    p {
      color: black;
      font-size: 0.8em;
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .v-card {
    .v-icon {
      font-size: 2rem;
      color: white;
    }

    h1 {
      color: white;
      font-size: 1.2rem;
      margin-left: 5px;
    }

    h2 {
      color: white;
      font-size: 1rem;
      margin-left: 5px;
    }
  }
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

.card {
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 10px;
}

.v-expansion-panel-header {
  min-height: 48px;
  border-radius: 4px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.card-inside {
  height: 50%;
  justify-content: start;
  width: 90%;
  align-items: center;
  padding: 0 0 0 10px;
}

.button-submit {
  width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: transparent;
  color: #1a535e;
  border-radius: 2em;
  border-width: 2px;
  background: 0 0;
  font-weight: bold;
  margin-top: 10px;
}

.Header {
  font-weight: 700;
  font-size: 1.2em;
  height: 20px;
  margin-top: 10px;
  background-color: #d1dfe3;
  color: black;
}

.bg {
  flex-wrap: wrap;
  background-size: cover;
  background-color: #eef2f5;
}

a:hover {
  font-weight: bolder;
}

.card_request_div {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

th {
  border-bottom: 2px solid black;
}

td {
  text-align: center;
}

.image_card {
  width: 35%;
  margin: 10px 10px 0 10px;

  .image_ad {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .cards {
    width: 100%;
  }
}

.container_main {
  justify-content: center;
  height: wrap-content;

  .cards {
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .image_card {
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .card_request_div,
  .card_request_div_container {
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}

.card_item:nth-child(4n + 1) {
  .card-inside {
    background-color: #008baa !important;
  }
}

.card_item:nth-child(4n + 2) {
  .card-inside {
    background-color: #0187d9 !important;
  }
}

.card_item:nth-child(4n + 3) {
  .card-inside {
    background-color: #f8811c !important;
  }
}

.card_item:nth-child(4n + 4) {
  .card-inside {
    background-color: #a2d183 !important;
  }
}

.request_date {
  display: flex;
  width: 100%;
  flex-direction: row;
  font-size: 16px !important;

  .title {
    font-weight: 400;
    font-size: 1.1rem !important;
  }

  .date {
    margin-left: 20px;
    font-size: inherit;
    outline: #d1dfe3;
  }
}
</style>
